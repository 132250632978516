import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import ListGroup from "react-bootstrap/ListGroup";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Accordion from "react-bootstrap/Accordion";
import Col from "react-bootstrap/Col";
import React from "react";
import {TiSocialInstagram} from "react-icons/all";
function App() {
  return (
    <div className="App">
      <Container className="content-container" fluid>
        <Row className="header">
          <Col>
            <div className="title">one day in la</div>
            <div className="dates">1/22/22 → 1/23/22</div>
          </Col>
        </Row>
        <Row>
          <Accordion>
            <Accordion.Item eventKey="0">
              <Accordion.Header>fly</Accordion.Header>
              <Accordion.Body>
                <ListGroup as="ol">
                  <ListGroup.Item
                      as="li"
                      className="d-flex justify-content-between align-items-start"
                  >
                    <div className="ms-2 me-auto">
                      <div className="fw-bold">sea ✈ lax</div>
                      <p>1/22 @ 10:10 am</p>
                    </div>

                  </ListGroup.Item>
                  <ListGroup.Item
                      as="li"
                      className="d-flex justify-content-between align-items-start"
                  >
                    <div className="ms-2 me-auto">
                      <div className="fw-bold"> lax ✈ sea</div>
                      <p>1/23 @ 2:00 pm</p>
                    </div>
                  </ListGroup.Item>
                </ListGroup>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>eat</Accordion.Header>
              <Accordion.Body>
                <ListGroup as="ol">
                  <ListGroup.Item
                      as="li"
                      className="d-flex justify-content-between align-items-start"
                  >
                    <div className="ms-2 me-auto">
                      <div className="fw-bold">sugarfish</div>
                      <p>🍣 lunch, 1/22</p>
                    </div>
                    <a href="https://www.instagram.com/sugarfishbynozawa/?hl=en" target="_blank" rel="noreferrer">
                      <TiSocialInstagram></TiSocialInstagram>
                    </a>
                  </ListGroup.Item>
                  <ListGroup.Item
                      as="li"
                      className="d-flex justify-content-between align-items-start"
                  >
                    <div className="ms-2 me-auto">
                      <div className="fw-bold">bacetti trattoria</div>
                      <p>🍝 dinner, 1/22</p>
                    </div>
                    <a href="https://www.instagram.com/bacetti.la/?hl=en" target="_blank" rel="noreferrer">
                      <TiSocialInstagram></TiSocialInstagram>
                    </a>
                  </ListGroup.Item>
                  <ListGroup.Item
                      as="li"
                      className="d-flex justify-content-between align-items-start"
                  >
                    <div className="ms-2 me-auto">
                      <div className="fw-bold">republique</div>
                      <p>🥐 brunch, 1/23</p>
                    </div>
                    <a href="https://www.instagram.com/republiquecafebakery/?hl=en" target="_blank" rel="noreferrer">
                      <TiSocialInstagram></TiSocialInstagram>
                    </a>
                  </ListGroup.Item>
                </ListGroup>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
              <Accordion.Header>explore</Accordion.Header>
              <Accordion.Body>
                <ListGroup as="ol">
                  <ListGroup.Item
                      as="li"
                      className="d-flex justify-content-between align-items-start"
                  >
                    <div className="ms-2 me-auto">
                      <div className="fw-bold">lacma</div>
                      <p>🖼 afternoon, 1/22</p>
                    </div>
                    <a href="https://www.instagram.com/lacma/?hl=en" target="_blank" rel="noreferrer">
                      <TiSocialInstagram></TiSocialInstagram>
                    </a>
                  </ListGroup.Item>
                  <ListGroup.Item
                      as="li"
                      className="d-flex justify-content-between align-items-start"
                  >
                    <div className="ms-2 me-auto">
                      <div className="fw-bold">bar lis</div>
                      <p>🍸 evening, 1/22</p>
                    </div>
                    <a href="https://www.instagram.com/barlisla/?hl=en" target="_blank" rel="noreferrer">
                      <TiSocialInstagram></TiSocialInstagram>
                    </a>
                  </ListGroup.Item>
                  <ListGroup.Item
                      as="li"
                      className="d-flex justify-content-between align-items-start"
                  >
                    <div className="ms-2 me-auto">
                      <div className="fw-bold">runyon canyon</div>
                      <p>🏃🏻 morning, 1/23</p>
                    </div>
                    <a href="https://www.instagram.com/runyoncanyon/?hl=en" target="_blank" rel="noreferrer">
                      <TiSocialInstagram></TiSocialInstagram>
                    </a>
                  </ListGroup.Item>
                </ListGroup>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3">
              <Accordion.Header>rest</Accordion.Header>
              <Accordion.Body>
                <ListGroup as="ol">
                <ListGroup.Item
                    as="li"
                    className="d-flex justify-content-between align-items-start"
                >
                  <div className="ms-2 me-auto">
                    <div className="fw-bold">cara hotel</div>
                    <p>🛌 sleep, 1/22 → rise 1/23</p>
                  </div>
                  <a href="https://www.instagram.com/cara_hotel/?hl=en" target="_blank" rel="noreferrer">
                    <TiSocialInstagram></TiSocialInstagram>
                  </a>
                </ListGroup.Item>
                </ListGroup>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Row>
        <Row className="footer">
          <Col>
            <p>happy birthday, plbs ❤️</p>
            <p>i can't wait to spend the day with you.</p>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default App;
